import { OrderDeliveryMethod } from "@tential/ec-gql-schema/master/order"
import { haveMessageInError } from "@tential/ec-gql-schema/utils/functions/error"
import { COUPON_VALIDATE_MESSAGE, CHECK_PURCHASE_COUPON_ERROR_MESSAGE } from "~/const/coupon"
import { STRIPE_ERROR_REASON } from "~/const/stripe"
import { ToastedStateType } from "~/state/sessionStorage"

export const checkPurchaseErrorMessage = (
  message: string,
  isAmazonPay: boolean,
  deliveryMethod: OrderDeliveryMethod,
): string => {
  if (message === "eGift cannot be purchased with cash on delivery.")
    return "eギフトは代引きでのご注文はできません。他のお支払い方法をご利用ください。"
  else if (message === "eGift and embroidery products cannot be purchased at the same time.")
    return "刺繍商品はeギフト購入できません。"
  else if (message === "embroidery products cannot be purchased with cash on delivery.")
    return "刺繍商品は代引き引換で購入できません。"
  else if (message === "Embroidery products cannot be purchased with other products.")
    return "刺繍商品は通常商品と同時に購入できません。"
  else if (message === "Gift message cannot be added to embroidery products.")
    return "刺繍商品はギフトメッセージと同時に購入できません。"
  else if (message === "Bundle wrapping cannot be added to embroidery products.")
    return "刺繍商品は同梱資材と同時に購入できません。"
  else if (message === "Convenience store pickup cannot be selected with cash_on_delivery.")
    return "コンビニ受け取りを選択中は代引きが利用できません。他のお支払い方法を選ぶか、配送方法を「指定住所に配送」へ変更してください。"
  else if (message === "Convenience store pickup cannot be selected with amazon_pay.")
    return "コンビニ受け取りを選択中はAmazon Payが利用できません。他のお支払い方法を選ぶか、配送方法を「指定住所に配送」へ変更してください。"
  else if (message === "The total package size exceeds the 80 size limit for convenience store pickup.")
    return "コンビニ受け取りの配送サイズが上限を超えています。配送方法を「指定住所に配送」へ変更してください。"
  else if (message === "product is undefined" || message === "productIsUndefined")
    return "商品情報が見つかりませんでした。再度商品を選択してください。"
  else if (message === "skuIds is empty" || message === "skuIdsIsEmpty")
    return "商品の在庫情報が変更されました。新たに商品を選択してください。"
  else if (message === "wrappingSku is undefined" || message === "wrappingSkuIsUndefined")
    return "ラッピング商品情報が見つかりませんでした。再度ラッピングを選択してください。"
  else if (message === "wrappingSku is not enough" || message === "wrappingSkuIsNotEnough")
    return "ラッピング商品の在庫が足りません。再度ラッピングを選択してください。"
  else if (message === "messageSku is not found" || message === "messageSkuIsNotFound")
    return "メッセージカード商品情報が見つかりませんでした。再度メッセージカードを選択してください。"
  else if (message === "messageSku is not enough" || message === "messageSkuIsNotEnough")
    return "メッセージカード商品の在庫が足りません。再度メッセージカードを選択してください。"
  else if (message === "stockProducts is undefined" || message === "stockProductsIsUndefined")
    return "商品の在庫情報が変更されました。新たに商品を選択してください。"
  else if (message === "egift is undefined" || message === "eGiftIsUndefined") return "eギフト注文の作成に失敗しました"
  else if (message === "stock is not enough" || message === "stockIsNotEnough")
    return "在庫切れの商品が含まれていたためカートから削除しました。ご確認の上再度ご注文をお試しください"
  else if (message.includes("Failed to add temporary stock limit for skus"))
    return "ご希望の商品が在庫わずかのため確保できませんでした。再度ご注文をお試しください"
  else if (message === "eGift cannot be purchased with duvet collection.")
    return "羽毛布団回収方法が設定されている場合はeギフト購入できません。"
  else if (message === "Duvet collection cannot be selected with convenience store pickup.")
    return "この商品はコンビニ受け取りできません。再度お届け先を設定してください。"
  else if (message === "Duvet collection cannot be selected with leave at door.")
    return "この商品は置き配指定できません。"
  else if (message === "Duvet collection cannot be selected without duvet collection items.")
    return "羽毛布団回収設定にエラーが発生しました。お手数ですが、もう一度カートに商品を入れ直してください。"
  else if (STRIPE_ERROR_REASON[message]) return STRIPE_ERROR_REASON[message]
  else if (Object.values(COUPON_VALIDATE_MESSAGE).some((couponMessage) => message.includes(couponMessage)))
    return CHECK_PURCHASE_COUPON_ERROR_MESSAGE.CANCELLATION
  else if (deliveryMethod === "konbini_pickup")
    return "コンビニ受け取りがご利用いただけません。配送方法を「指定住所に配送」へ変更してください"

  return isAmazonPay
    ? "Amazon Payのお支払いに失敗しました。他のお支払い方法をご利用ください。"
    : "決済に失敗しました。別のカードをお試しください。"
}

export const commonErrorHandleForTryCatch = (
  error: unknown,
  toast?: ToastedStateType,
  functionName?: string,
  toastMessage?: string,
): void => {
  if (haveMessageInError(error)) {
    functionName ? console.error(functionName, error.message) : console.error(error.message)
    toast?.showErrorToasted(error.message)
  } else {
    functionName ? console.error(functionName, error) : console.error(error)
    toast?.showErrorToasted(toastMessage || String(error) || "不明なエラーが発生しました")
  }
}

export const replaceGraphQLError = (message?: string): string => {
  return message?.toString().replace("[GraphQL] ", "") || ""
}
